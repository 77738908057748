

export default function Footer(params) {

    return (
        <>
            <footer class="bg-gray-800/80 border-t border-gray-700 mb-24">
                <div class="container mx-auto px-4 py-8">
                    <div class="text-center text-gray-400 text-sm">
                        <p>© 2025 TheCodeFather. All rights reserved.</p>
                    </div>
                </div>
            </footer>

        </>
    )
}