import React, { useEffect, useState } from "react";
import { generateClient } from 'aws-amplify/api';
import { motion } from 'framer-motion';
import Swal from 'sweetalert2';
import { PaymentHnadler, createPaymentLink } from "./PaymentHandler";
import Footer from "./Footer";
import BottomNavbar from './BottomNavbar';
import { getAuraStoreItems } from '../graphql/queries';

const client = generateClient();

export default function Pubg() {
    const [stepOneError, setStepOneError] = useState(false);
    const [stepOneErrorMessage, setStepOneErrorMessage] = useState("");
    const [stepTwoError, setStepTwoError] = useState(false);
    const [recharging, setRecharging] = useState(false);
    const [rechargeWarning, setRechargeWarning] = useState(false);
    const [myYokcashDict, setYokcashDict] = useState([]);
    const [myAmountDict, setMyAmountDict] = useState([]);
    const [isLive, setIsLive] = useState("load");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [emailID, setEmailID] = useState("");
    const [userInfoSaved, setUserInfoSaved] = useState(false);
    const [showID, setShowID] = useState(false);
    const [userid, setUserID] = useState("");
    const [serverid, setServerID] = useState("");
    const [selectedValue, setSelectedValue] = useState();
    const [amount, setAmount] = useState();
    const [ign, setIgn] = useState('');
    const [selectedPack, setSelectedPack] = useState(null);

    useEffect(() => {
        getPack();
        try {
            setEmailID(localStorage.getItem('emailid') || "");
            setPhoneNumber(localStorage.getItem('phoneno') || "");
        } catch (error) {
            console.log(error);
        }
    }, []);

    const handleChange = (event) => {
        setSelectedValue(JSON.parse(event.target.value)['item']);
        setAmount(JSON.parse(event.target.value)['price']);
    };

    const handleServerChange = (event) => {
        setServerID(event.target.value)
    }

    const handlePackSelect = (event) => {
        setSelectedPack(event.target.value);
    };

    async function getPack() {
        const apiData = await client.graphql({
            query: getAuraStoreItems,
            variables: { itemname: 'store-pubg' },
        });
        if (apiData["data"]['getAuraStoreItems']) {
            setYokcashDict(JSON.parse(apiData["data"]['getAuraStoreItems']['storeitems']));
            setIsLive(apiData["data"]['getAuraStoreItems']['isactive']);
        }
    }

    const getIGN = () => {
        if (userid === "" || serverid === "") {
            Swal.fire({ icon: "error", title: "Please enter user id and zone id!" });
        } else {
            setIgn("");
            var xhr = new XMLHttpRequest();
            xhr.addEventListener("readystatechange", function () {
                if (this.readyState === 4) {
                    let recjson = JSON.parse(this.responseText);
                    if (recjson["body"]) {
                        setIgn(decodeURIComponent(recjson["body"]).slice(1, -1));
                    } else {
                        Swal.fire({ icon: "error", title: "User with this id not found" });
                    }
                }
            });
            xhr.open("GET", `https://chp701oq1m.execute-api.ap-south-1.amazonaws.com/v1/get?userid=${userid}&zoneid=${serverid}`);
            xhr.send();
        }
    };

    const handlePayment = () => {
        var validRegex = /\S+@\S+\.\S+/;
        var phoneno = /^\d{10}$/;
        if (userid === "") {
            Swal.fire({ icon: 'warning', text: 'Please enter your user ID' });
        } else if (amount === undefined) {
            Swal.fire({ icon: 'warning', text: 'Please select pack' });
        } else if (emailID === "") {
            Swal.fire({ icon: 'error', text: 'Please enter proper email address' });
        } else if (phoneNumber === "") {
            Swal.fire({ icon: 'error', text: 'Please enter proper phone number' });
        } else if (!emailID.match(validRegex)) {
            Swal.fire({ icon: 'error', text: 'Please enter proper email address' });
        } else {
            let props = {
                userID: userid,
                serverID: serverid,
                amount: amount,
                selectedPack: selectedPack,
                emailID: emailID,
                phoneNumber: phoneNumber,
                ordertype: "aura-yokpubg"
            };
            try {
                setRecharging(true);
                PaymentHnadler(props).then(orderJson => createPaymentLink(props, orderJson));
                setRecharging(false);
            } catch (e) {
                console.log(e);
                Swal.fire({ icon: 'error', text: 'Something went wrong! Please try again later.' });
                setRecharging(false);
            }
        }
    };

    return (
        <>
            <nav className="sticky top-0 bg-gray-800/80 backdrop-blur-md z-50">
                <div className="container mx-auto px-4 py-3 flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                        <span className="w-8 h-8 bg-purple-500 rounded-lg animate-pulse">
                            <img className='rounded-md' src="/android-launchericon-512-512.png" alt="Logo" />
                        </span>
                        <h1 className="text-xl font-bold bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">
                            Aura Official Store
                        </h1>
                    </div>
                </div>
            </nav>

            <main className="container mx-auto px-4 py-6">
                <section className="mb-8">
                    <div className="rounded-2xl overflow-hidden border border-gray-700/50">
                        <img src="/pubg.jpg" alt="PUBG Banner" className="w-full h-64 object-cover" />
                    </div>
                </section>

                <section className="mb-12">
                    <h3 className="text-xl font-semibold mb-3">PUBG</h3>
                    <p className="text-sm text-white-400/75 text-justify font-semibold mb-6" >
                    After success on the PC game, PUBG or Player Unknown Battle Ground began to penetrate into Mobile Phone. 
                    One of the most favorite Battle Royale games can be played through Smartphones. 
                    Still the same as the PC version, to win the game you have to survive until there is no opponent left or Chicken Dinner</p>
                    {isLive === "true" ? (
                        <>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="bg-gray-800/50 rounded-xl p-6 border border-gray-700 hover:border-purple-500 transition-all">

                                    <h4 className="text-lg font-semibold mb-4"><span style={{ padding: '3px', width: '34px', display: 'inline-block', textAlign: 'center', borderRadius: "50%", background: '#44D62C', color: 'black' }}>1</span> &nbsp; Contact Information</h4>
                                    <input
                                        type="email"
                                        placeholder="Email ID"
                                        value={emailID}
                                        onChange={(e) => setEmailID(e.target.value)}
                                        className="w-full p-2 mb-4 bg-gray-700/50 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                                    />
                                    <input
                                        type="tel"
                                        placeholder="Mobile Number"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value.replace(/[^0-9]/g, '').slice(0, 10))}
                                        className="w-full p-2 mb-4 bg-gray-700/50 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                                    />
                                    <button
                                        onClick={() => {
                                            if (!emailID.match(/\S+@\S+\.\S+/)) {
                                                Swal.fire({ icon: 'error', text: 'Please enter a valid email address' });
                                            }
                                            else if (phoneNumber.length !== 10) {
                                                Swal.fire({ icon: 'error', text: 'Please enter a valid phone number' });
                                            }
                                            else {
                                                Swal.fire({ icon: 'success', text: 'Information saved.' });
                                            }
                                        }}
                                        className="w-full bg-purple-600 hover:bg-purple-700 text-white py-2 rounded-lg transition-all"
                                    >
                                        Save
                                    </button>
                                </div>

                                <div className="bg-gray-800/50 rounded-xl p-6 border border-gray-700 hover:border-purple-500 transition-all">
                                    <h4 className="text-lg font-semibold mb-4"><span style={{ padding: '3px', width: '34px', display: 'inline-block', textAlign: 'center', borderRadius: "50%", background: '#44D62C', color: 'black' }}>2</span> &nbsp;Player Information</h4>
                                    <input
                                        type="text"
                                        placeholder="User ID"
                                        value={userid}
                                        onChange={(e) => setUserID(e.target.value)}
                                        className="w-full p-2 mb-4 bg-gray-700/50 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500"
                                    />
                                    <h4 className="text-center mt-2">{ign}</h4>
                                    {/* <button
                                        onClick={getIGN}
                                        className="w-full bg-purple-600 hover:bg-purple-700 text-white py-2 rounded-lg transition-all"
                                    >
                                        Verify Player
                                    </button> */}
                                </div>
                            </div>

                            <div className="mt-8">
                                <h4 className="text-lg font-semibold mb-4 text-center"><span style={{ padding: '3px', width: '34px', display: 'inline-block', textAlign: 'center', borderRadius: "50%", background: '#44D62C', color: 'black' }}>3</span> &nbsp;Diamond Packages</h4>
                                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4">
                                    {myYokcashDict.map((element) => (
                                        element.isactive === "true" && (
                                            <div
                                                key={element.name}
                                                onClick={() => {
                                                    setSelectedPack(element.name);
                                                    setAmount(element.price);
                                                }}
                                                className={`bg-gray-800/50 rounded-xl p-4 text-center cursor-pointer border ${selectedPack === element.name
                                                        ? "border-purple-500"
                                                        : "border-gray-700"
                                                    } hover:border-purple-500 transition-all`}
                                            >
                                                <p className="font-semibold">{element.name}</p>
                                                <img
                                                    src={element.img.startsWith("https") ? `https://www.auraofficialstore.com/${element.img}` : element.img}
                                                    alt="Diamonds"
                                                    className="w-12 h-12 mx-auto my-2"
                                                />
                                                <p className="text-sm">
                                                    <del className="text-red-500">₹{element.mrp}</del> ₹{element.price}
                                                </p>
                                            </div>
                                        )
                                    ))}
                                </div>
                            </div>

                            <div className="text-center mt-8">
                                {recharging ? (
                                    <div className="w-full bg-purple-500/20 h-2 rounded-full animate-pulse"></div>
                                ) : (
                                    <button
                                        onClick={handlePayment}
                                        className="bg-purple-600 hover:bg-purple-700 text-white px-8 py-3 rounded-lg font-semibold transition-all transform hover:scale-105"
                                    >
                                        Recharge Diamonds
                                    </button>
                                )}
                            </div>
                        </>
                    ) : (
                        <div className="bg-gray-800/50 rounded-xl p-6 border border-gray-700 text-center">
                            <p className="text-lg">
                                Recharge for this game is currently unavailable. <br />
                                Please try again later. We appreciate your patience.
                            </p>
                        </div>
                    )}
                </section>
            </main>

            <BottomNavbar />
            <Footer />
        </>
    );
}