import React from 'react';
import { motion } from 'framer-motion';
import {
    Typography,
    Container,
    Box,
    Grid,
    Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';

import Footer from './Footer';
import BottomNavbar from './BottomNavbar';

const SuccessPaper = styled(Paper)(({ theme }) => ({
    background: 'linear-gradient(145deg, #1e1e2f, #16162a)',
    borderRadius: '15px',
    padding: theme.spacing(4),
    color: '#ffffff',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.5)',
    border: '1px solid rgba(255,255,255,0.1)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
}));

export default function Success() {
    return (
        <>
            <nav className="sticky top-0 bg-gray-800/80 backdrop-blur-md z-50">
                <div className="container mx-auto px-4 py-3 flex items-center justify-between">
                    <div className="flex items-center space-x-2">
                        <span className="w-8 h-8 bg-purple-500 rounded-lg animate-pulse rounded-md">
                            <img className='rounded-md' src="/android-launchericon-512-512.png" />
                        </span>
                        <h1
                            className="text-xl font-bold bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">
                            Aura Official Store
                        </h1>
                    </div>
                </div>
            </nav>
            <Container maxWidth="lg">
                {/* <GameImage src='/mlbb.jpg' alt="mlbb" /> */}
                <Box sx={{
                    background: 'linear-gradient(to right, #1a1a2e, #16213e)',
                    // minHeight: '100vh',
                    pt: 4,
                    pb: 4,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Grid container justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <SuccessPaper>
                                <Typography
                                    variant="h3"
                                    gutterBottom
                                    sx={{
                                        color: '#ffffff',
                                        fontWeight: 'bold',
                                        textShadow: '0 0 10px rgba(37, 117, 252, 0.5)',
                                        mb: 3
                                    }}
                                >
                                    Aura Official Store
                                </Typography>

                                <motion.div
                                    initial={{ opacity: 0, y: 50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.6 }}
                                >
                                    <Typography
                                        variant="h5"
                                        color="primary"
                                        sx={{
                                            // color: '#00f0ff', 
                                            mb: 2
                                        }}
                                    >
                                        Payment Received Successfully!
                                    </Typography>

                                    <Typography
                                        variant="body1"
                                        sx={{
                                            color: 'white',
                                            mb: 3,
                                            opacity: 0.8
                                        }}
                                    >
                                        Please wait, your diamonds are being loaded into your account
                                    </Typography>

                                    <motion.img
                                        src="/odette_thankyou.gif"
                                        alt="Thank you"
                                        style={{
                                            width: '250px',
                                            height: '250px',
                                            borderRadius: '15px',
                                            boxShadow: '0 10px 30px rgba(0, 0, 0, 0.3)'
                                        }}
                                        initial={{ scale: 0.8, opacity: 0 }}
                                        animate={{ scale: 1, opacity: 1 }}
                                        transition={{ duration: 0.6 }}
                                    />
                                </motion.div>
                            </SuccessPaper>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Footer />
            <BottomNavbar />
        </>
    );
}