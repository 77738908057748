import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Amplify } from 'aws-amplify';

import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import config_json from './exports';



import MlbbNew from './components/MlbbNew';
import History from './components/History';
import Success from './components/Success';
import Fail from './components/Fail';
import HomePage from './components/HomePage';
import GenshinImpact from './components/GenshinImpact';
import Pubg from './components/Pubg';
const theme = createTheme();

Amplify.configure(config_json);


function App() {
  
  return (
    <>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Routes>
          <Route
            path="/"
            element={<HomePage />}
          />
          <Route
            path="/history"
            element={<History />}
          />
           <Route
            path="/mlbb"
            element={<MlbbNew />}
          />
          <Route
           path="/genshinimpact"
           element={<GenshinImpact />}
         />
         <Route
          path="/pubg"
          element={<Pubg />}
        />
          <Route
           path="/success"
           element={<Success />}
         />
         <Route
          path="/fail"
          element={<Fail />}
        />
           </Routes>
      </Router>
    </ThemeProvider>
    </>
    );
}

export default App;
