import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { 
    TextField, 
    Button, 
    Typography, 
    Container, 
    Box, 
    Grid, 
    Paper 
} from '@mui/material';
import { styled } from '@mui/material/styles';
// import { SearchIcon } from 'lucide-react';
import SearchIcon from '@mui/icons-material/Search';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { generateClient } from 'aws-amplify/api';
import { getAuraStoreUserHistory } from '../graphql/queries';

import BottomNavbar from './BottomNavbar';
import OrdersTable from './OrdersTable';

const client = generateClient();

const HistoryPaper = styled(Paper)(({ theme }) => ({
    background: 'linear-gradient(145deg, #1e1e2f, #16162a)',
    borderRadius: '15px',
    padding: theme.spacing(3),
    color: '#ffffff',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.5)',
    border: '1px solid rgba(255,255,255,0.1)',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.02)',
    }
}));

const GameImage = styled('img')({
    width: '100%',
    height: '300px',
    objectFit: 'cover',
    transition: 'transform 0.3s ease',
    '&:hover': {
        transform: 'scale(1.1)',
    }
});

const SearchButton = styled(Button)(({ theme }) => ({
    background: 'linear-gradient(45deg, #6a11cb 0%, #2575fc 100%)',
    color: '#ffffff',
    padding: theme.spacing(1.5, 3),
    borderRadius: '25px',
    fontWeight: 'bold',
    textTransform: 'none',
    transition: 'all 0.3s ease',
    '&:hover': {
        background: 'linear-gradient(45deg, #2575fc 0%, #6a11cb 100%)',
        transform: 'translateY(-3px)',
        boxShadow: '0 4px 17px rgba(0, 0, 0, 0.35)',
    }
}));

export default function History() {
    const [userID, setUserID] = useState('');
    const [serverID, setServerID] = useState('');
    const [showID, setShowID] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userOrders, setUsersOrder] = useState(null);

    async function getOrders() {
        setLoading(true);
        setUsersOrder([]);
        let searchString = `${userID}${serverID.length>0?'-'+serverID:""}`
        try {
            const apiData = await client.graphql({
                query: getAuraStoreUserHistory,
                variables: {
                    userid: searchString,
                },
            });

            if (apiData["data"]['getAuraStoreUserHistory']) {
                setUsersOrder(JSON.parse(apiData["data"]['getAuraStoreUserHistory']['orders']))
            } else {
                setUsersOrder([]);
            }
        } catch (error) {
            console.error("Error fetching order history:", error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            {/* <Footer /> */}
            <Container maxWidth="lg">
                <GameImage src='/mlbb.jpg' alt="mlbb" />
                <Box sx={{
                    background: 'linear-gradient(to right, #1a1a2e, #16213e)',
                    minHeight: '100vh',
                    pt: 4,
                    pb: 4
                }}>
                    <Typography
                        variant="h3"
                        align="center"
                        gutterBottom
                        sx={{
                            color: '#ffffff',
                            fontWeight: 'bold',
                            textShadow: '0 0 10px rgba(37, 117, 252, 0.5)'
                        }}
                    >
                        Order History
                    </Typography>

                    <Grid container spacing={3} justifyContent="center">
                        <Grid item xs={12} md={8}>
                            <HistoryPaper>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <Typography variant="h5" sx={{ flexGrow: 1 }}>
                                        Player Order Details
                                    </Typography>
                                    <HelpOutlineIcon 
                                        onClick={() => setShowID(!showID)} 
                                        style={{ cursor: 'pointer', color: 'white' }} 
                                    />
                                </Box>

                                {showID && (
                                    <motion.img 
                                        src='/ss1.jpg' 
                                        alt="Help" 
                                        style={{ 
                                            width: '100%', 
                                            height: 'auto', 
                                            objectFit: 'cover',
                                            marginBottom: '16px'
                                        }}
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.5 }}
                                    />
                                )}

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="User ID"
                                            variant="outlined"
                                            value={userID}
                                            onChange={(e) => setUserID(
                                                e.target.value
                                                    .replaceAll("(", "")
                                                    .replaceAll(")", "")
                                                    .replaceAll(" ", "")
                                            )}
                                            sx={{
                                                '& .MuiInputBase-input': { color: 'white' },
                                                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                                                '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                                                '& .MuiInputLabel-root': { color: 'white' },
                                                '& .MuiInputLabel-root.Mui-focused': { color: 'white' },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Server ID"
                                            variant="outlined"
                                            value={serverID}
                                            onChange={(e) => setServerID(
                                                e.target.value
                                                    .replaceAll("(", "")
                                                    .replaceAll(")", "")
                                                    .replaceAll(" ", "")
                                            )}
                                            sx={{
                                                '& .MuiInputBase-input': { color: 'white' },
                                                '& .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                                                '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
                                                '& .MuiInputLabel-root': { color: 'white' },
                                                '& .MuiInputLabel-root.Mui-focused': { color: 'white' },
                                            }}
                                        />
                                    </Grid>
                                </Grid>

                                <Box sx={{ textAlign: 'center', mt: 2 }}>
                                    <SearchButton
                                        variant="contained"
                                        onClick={getOrders}
                                        startIcon={<SearchIcon />}
                                    >
                                        Check Order History
                                    </SearchButton>
                                </Box>
                            </HistoryPaper>
                        </Grid>
                    </Grid>
                    {userOrders ? (
                        <Box sx={{ mt: 4 }}>
                            <OrdersTable rows={userOrders} />
                        </Box>
                    ) : (
                        <Typography 
                            variant="h6" 
                            align="center" 
                            sx={{ 
                                color: 'white', 
                                mt: 4, 
                                opacity: loading ? 0.5 : 1 
                            }}
                        >
                         {loading ? "Loading..." : "No orders found. Please enter User ID and Server ID."}
                        </Typography>
                    )}
                </Box>
            </Container>
            <BottomNavbar page='history' />
        </>
    );
}