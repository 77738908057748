import { useNavigate } from 'react-router-dom';
import Footer from "./Footer";
import BottomNavbar from './BottomNavbar';

import React from 'react';

export default function HomePage(params) {

    const navigate = useNavigate();

    return (<>
        <nav className="sticky top-0 bg-gray-800/80 backdrop-blur-md z-50">
            <div className="container mx-auto px-4 py-3 flex items-center justify-between">
                <div className="flex items-center space-x-2">
                    <span className="w-8 h-8 bg-purple-500 rounded-lg animate-pulse rounded-md">
                        <img className='rounded-md' src="/android-launchericon-512-512.png" />
                    </span>
                    <h1
                        className="text-xl font-bold bg-gradient-to-r from-purple-400 to-pink-500 bg-clip-text text-transparent">
                        Aura Official Store
                    </h1>
                </div>
            </div>
        </nav>

        {/* <!-- Main Content --> */}
        <main className="container mx-auto px-4 py-6">
            <section className="mb-8">
                <div className="swiper rounded-2xl overflow-hidden border border-gray-700/50">
                    <div className="swiper-wrapper">
                        {/* <!-- Slide 1 --> */}
                        <div className="swiper-slide relative">
                            <img src="/banner.png" className="w-full h-64 object-fill lg:object-cover object-right" />
                        </div>
                        {/* <!-- Add 2 more slides --> */}
                    </div>
                    <div className="swiper-pagination !bottom-2"></div>
                </div>
            </section>

            {/* <!-- Categories --> */}
            <section className="mb-12">
                <h3 className="text-xl font-semibold mb-6">Trending Games</h3>
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                    {/* <!-- MLBB Card --> */}
                    <div
                        onClick={() => navigate("/mlbb")}
                        className="relative group bg-gray-800/50 rounded-xl p-3 hover:bg-gray-700/30 transition-all cursor-pointer border border-gray-700 hover:border-purple-500">
                        <div className="absolute inset-0 bg-gradient-to-t from-purple-500/10 to-transparent rounded-xl"></div>
                        <div className="relative z-10">
                            <div className="flex items-center space-x-4">
                                <img src="/mlbb.jpeg" alt="MLBB" className="w-16 h-16 object-cover" style={{ borderRadius: "5px" }} />
                                <div>
                                    <h4 className="font-semibold">Mobile Legends</h4>
                                    <p className="text-sm text-gray-400">From ₹15</p>
                                </div>
                            </div>
                            <span
                                className="absolute top-4 right-4 bg-purple-500/20 text-purple-400 px-3 py-1 rounded-full text-xs">Popular</span>
                        </div>
                    </div>

                    {/* <!-- Genshin Card --> */}
                    <div
                        onClick={() => navigate("/genshinimpact")}
                        className="relative group bg-gray-800/50 rounded-xl p-3 hover:bg-gray-700/30 transition-all cursor-pointer border border-gray-700 hover:border-blue-500">
                        <div className="absolute inset-0 bg-gradient-to-t from-blue-500/10 to-transparent rounded-xl"></div>
                        <div className="relative z-10">
                            <div className="flex items-center space-x-4">
                                <img src="/genshin.jpg" alt="Genshin" className="w-16 h-16 object-cover" style={{ borderRadius: "5px" }} />
                                <div>
                                    <h4 className="font-semibold">Genshin Impact</h4>
                                    <p className="text-sm text-gray-400">From ₹90</p>
                                </div>
                            </div>
                            <span
                                className="absolute top-4 right-4 bg-blue-500/20 text-blue-400 px-3 py-1 rounded-full text-xs">New</span>
                        </div>
                    </div>

                    {/* <!-- PUBG Card --> */}
                    <div
                        onClick={() => navigate("/pubg")}
                        className="relative group bg-gray-800/50 rounded-xl p-3 hover:bg-gray-700/30 transition-all cursor-pointer border border-gray-700 hover:border-red-500">
                        <div className="absolute inset-0 bg-gradient-to-t from-red-500/10 to-transparent rounded-xl"></div>
                        <div className="relative z-10">
                            <div className="flex items-center space-x-4">
                                <img src="/pubg.jpg" alt="PUBG" className="w-16 h-16 object-cover" style={{ borderRadius: "5px" }} />
                                <div>
                                    <h4 className="font-semibold">PUBG Mobile</h4>
                                    <p className="text-sm text-gray-400">From ₹65</p>
                                </div>
                            </div>
                            <span
                                className="absolute top-4 right-4 bg-red-500/20 text-red-400 px-3 py-1 rounded-full text-xs">-15%
                                Off</span>
                        </div>
                    </div>
                </div>
            </section>

            {/* <!-- Featured Products --> */}
            <section className="mb-12">
                <div className="flex items-center justify-between mb-6">
                    <h3 className="text-xl font-semibold">Hot Deals 🔥</h3>
                    <a href="#" className="text-purple-400 hover:text-purple-300 flex items-center">
                        See All
                        <svg className="w-4 h-4 ml-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                        </svg>
                    </a>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {/* <!-- Product Card 1 --> */}
                    <div
                        className="bg-gray-800/50 rounded-xl p-6 border border-gray-700 hover:border-purple-500 transition-all group">
                        <div className="flex justify-between items-start mb-4">
                            <div>
                                <h4 className="font-semibold">MLBB Diamonds</h4>
                                <p className="text-sm text-gray-400">Weekly Diamonds Pass</p>
                            </div>
                            <span className="bg-purple-500/20 text-purple-400 px-3 py-1 rounded-full text-sm">Limited</span>
                        </div>
                        <div className="flex justify-between items-end">
                            <div>
                                <p className="text-2xl font-bold">₹125</p>
                                <p className="text-sm text-gray-400 line-through">₹199</p>
                            </div>
                            <button
                                onClick={() => navigate("/mlbb")}
                                className="bg-purple-600 hover:bg-purple-700 px-6 py-2 rounded-lg transition-all transform hover:scale-105">
                                Buy Now
                            </button>
                        </div>
                        <div className="mt-4">
                            <div className="h-1 bg-gray-700 rounded-full">
                                <div className="w-3/4 h-1 bg-purple-500 rounded-full"></div>
                            </div>
                            <p className="text-xs text-gray-400 mt-1">74% claimed</p>
                        </div>
                    </div>

                    {/* <!-- Add 2 more product cards with similar structure --> */}
                </div>
            </section>

            {/* <!-- Features Section --> */}
            <section className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
                <div className="bg-gray-800/50 p-6 rounded-xl border border-gray-700">
                    <div className="w-12 h-12 bg-purple-500/20 rounded-xl flex items-center justify-center mb-4">
                        <svg className="w-6 h-6 text-purple-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <h4 className="font-semibold mb-2">Instant Delivery</h4>
                    <p className="text-gray-400 text-sm">Get your diamonds within 30 seconds</p>
                </div>
                {/* <!-- Add 2 more feature cards --> */}
            </section>
        </main>




        <BottomNavbar />
        <Footer />


    </>)
}